<template>
  <div class="news">
    <div class="row justify-content-start" v-show="!detailFlag">
      <div class="col-2"></div>
      <div class="col-6" style="margin: 50px 0px 0px 0px;">
        <div>
          <p style="text-align:left; font-weight: bold;">企业动态</p>
          <hr />
        </div>
        <div class="row justify-content-start" v-for="item in newsList" :key="item.url">
          <div class="col-5">
            <img :src="item.imageHead" style="width:350px; height:197px; float:left;" />
          </div>
          <div class="col-7">
            <p
              style="text-align:left; font-weight: bold; font-size:14px; margin-top:10px;"
            >{{item.title}}</p>
            <p style="text-align:left; color:#666666; font-size:12px;">{{item.createTime}}</p>
            <p style="text-align:left; font-size:14px; height:60px;">{{item.contentHead}}</p>
            <p style="text-align:left;">
              <button class="button" @click="toDetail(item.id)">查看详情</button>
            </p>
          </div>
        </div>
        <!-- <nav aria-label="Page navigation example" style="margin:30px 0 0 0;">
          <ul class="pagination justify-content-center">
            <li class="page-item disabled">
              <a class="page-link" href="#" tabindex="-1">页码</a>
            </li>
            <li class="page-item" v-for="item in pageNum" :key="item.index">
              <a class="page-link" @click="switchPage(item)">{{item}}</a>
            </li>
          </ul>
        </nav>-->
      </div>
      <div class="col-2">
        <v-side style="width:240px; height:600px; margin: 50px 0px 0px 0px;" />
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Side from '../components/Side'
const url = 'https://www.chinaybop.com/file'
export default {
  components: {
    'v-side': Side
  },
  mounted() {
    this.pageNum = Math.ceil(this.companyList.length / 5)
    this.currentCompanyList = this.companyList.slice(0, 5)
    this.getAllNews()
  },
  data() {
    return {
      pageNum: null,
      currentPage: 1,
      companyList: [
        {
          img: url + '/1.jpg',
          url: 'https://www.baidu.com',
          title: '隐形车衣干货分享 双十二隐形车衣选购攻略',
          time: '2020/12/12',
          content:
            '双十二马上就要到了，今天为大家带来一篇关于隐形车衣的干货分享！...'
        },
        {
          img: url + '/2.jpg',
          url: 'https://www.baidu.com',
          title: '隐形保镖车衣怎么样？好不好？',
          time: '2020/11/19',
          content:
            '随着隐形车衣进入市场，它优异的性能被越来越多车主所认可，汽车膜品牌繁多，但是会发现最畅销的隐形车衣也就是有着相同点...'
        },
        {
          img: url + '/3.jpg',
          url: 'https://www.baidu.com',
          title: '隐形保镖隐形车衣好不好？会不会发黄？',
          time: '2020/11/19',
          content:
            '隐形车衣的出现解决了车主对漆面保养的需求，市面上隐形车衣品牌很多，我们可以根据不同的需求选择隐形车衣...'
        },
        {
          img: url + '/4.jpg',
          url: 'https://www.baidu.com',
          title: '隐形车衣的哪种材质比较好？使用寿命长吗？',
          time: '2020/11/19',
          content:
            '隐形车衣作为目前市面上最好的漆面保养方式，贴上可以有效的防止漆面被刮花，划痕自动修复、防紫外线、提升漆面亮度等功能，隐形车衣的性能与材质密切相关。...'
        },
        {
          img: url + '/5.jpg',
          url: 'https://www.baidu.com',
          title: '汽车隐形车衣都有什么材质？隐形车衣有没有必要安装？',
          time: '2020/11/19',
          content:
            '作为车主，都希望爱车无论什么时候都能保持洁净，隐形车衣相比较传统漆面保养方式而言，可以在不伤害原厂漆的情况下提高漆面亮度、并且保持时间长；出于对爱车的保护，贴上了隐形车衣...'
        },
        {
          img: url + '/6.jpg',
          url: 'https://www.baidu.com',
          title: '在日常用车中你是不是也遇到过这样的困扰？',
          time: '2020/11/19',
          content:
            '隐形车衣是汽车漆面养护的终极解决方案，比起传统的打蜡、封釉，透明膜的维护层具有更佳的维护作用且不伤车漆，可实现长久的持续维护作用...'
        },
        {
          img: url + '/7.jpg',
          url: 'https://www.baidu.com',
          title: '如何选择一款高质量的车衣？',
          time: '2020/11/19',
          content:
            '每种产品都有自己的特点，而YEECAR的优点就是产品质量、服务体系、性价比高，YEECAR车衣采用的是进口材质，采用的是脂肪族TPU，...'
        },
        {
          img: url + '/8.jpg',
          url: 'https://www.baidu.com',
          title: 'TPU、TPH、PVC，隐形车衣材质哪种好？',
          time: '2020/11/19',
          content:
            '就目前来说，隐形车衣的材质比较常见的主要有三种，分别是TPU、TPH、PVC，可如果细究的话，应该是TPU、PVC、PU三种才对。粗糙就粗糙一点，就TPU、TPH、PVC这三种隐形车衣材质来说，哪种好呢？...'
        }
      ],
      currentCompanyList: [],
      newsList: []
    }
  },
  methods: {
    switchPage(page) {
      this.currentCompanyList = this.companyList.slice(page * 5 - 5, page * 5)
    },
    toDetail(id) {
      this.$router.push({ path: 'newsDetail', query: { id: id } })
    },
    getAllNews() {
      return new Promise((resolve, reject) => {
        axios
          .get('/e-car/chinaybop-news/getAll')
          // .get('http://localhost:9091/car/news/getAll')
          .then(res => {
            this.newsList = res.data
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
</script>

<style scoped>
@import '../assets/css/news.css';
</style>
