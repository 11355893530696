<template>
  <div class="side">
    <div class="side-bg1" style="background-color:#000000;opacity:1;">
      <h2 style="color:#fff; padding-top:60px">Paint</h2>
      <h2 style="color:#fff">Protection Film</h2>
      <p style="color:#fff; font-size:14px;">隐形保镖</p>
      <p style="color:#fff; font-size:14px;">您身边的漆面保护专家</p>
      <button class="button">热线电话</button>
      <p style="color:#fff; padding: 10px 0px 60px 0px; font-weight: bold;">400-182-7879</p>
    </div>
    <div style="margin:30px 0 0 0;">
      <span style="color:#000000; font-size:20px; font-weight: bold; text-align:left;">站内导航</span>
      <hr style="margin: 10px 0px 10px 0px;" color="#ecbd4d" />
      <a class="u-a" @click="switchMenu('/')">网站首页</a>
      <hr style="margin: 5px 0px 15px 0px;" />
      <a class="u-a" @click="switchMenu('/news')">新闻中心</a>
      <hr style="margin: 5px 0px 15px 0px;" />
      <!-- <a class="u-a" href="/news">案例中心</a>
      <hr style="margin: 5px 0px 15px 0px;" /> -->
      <!-- <a class="u-a" href="/news">质保查询</a>
      <hr style="margin: 5px 0px 15px 0px;" /> -->
      <!-- <a class="u-a" href="/news">报价查询</a>
      <hr style="margin: 5px 0px 15px 0px;" /> -->
      <a class="u-a" @click="switchMenu('/about')">关于我们</a>
      <hr style="margin: 5px 0px 15px 0px;" />
      <a class="u-a" @click="switchMenu('/contact')">联系我们</a>
      <hr style="margin: 5px 0px 15px 0px;" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Side',
  methods: {
    switchMenu(path) {
      this.$router.push({ path: path, params: {} })
    }
  }
}
</script>

<style scoped>
@import '../assets/css/side.css';
</style>
